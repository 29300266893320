<template>
  <div class="page">
    <div class="box1">
      <div class="main_box">
        <div class="ttl">集团总指挥中心<br />解决方案</div>
        <div class="sub_ttl">
          集团及高管进行全国所有分子公司及项目全盘实时管理
        </div>
        <a
          class="btn"
          href="http://p.qiao.baidu.com/cps/chat?siteId=13160731&userId=27403999&siteToken=839057cc7afae96bcdd508339dc7a783"
          target="_blank"
        >
          咨询服务
        </a>
      </div>
    </div>
    <div class="box2">
      <div class="main_box">
        <div class="ttl">分子公司、项目全盘监控</div>
        <div class="sub_ttl">
          墨器星盘通过智能AI物联网技术，将分子公司、项目的数据集中于墨器星盘管理，让集团做到真正的总指挥中心。
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="main_box">
        <div class="ttl">集团数据协同</div>
        <div class="sub_ttl">
          数据星盘不仅能在电脑、数据大屏查看，还能在手机上随时查看，实现高效的协同和持续的工作流。
        </div>
      </div>
    </div>
    <div class="box4">
      <div class="title">定制集团专属星盘</div>
      <div class="main_box" flex="main:justify">
        <div class="col" flex-box="0">
          <img
            class="icon"
            src="@/assets/img/ic_200_data.png"
            alt="数据私有化"
          />
          <div class="ttl">数据私有化</div>
          <div class="desc">
            系统免费升级免费维护，数据可本地存储，可自主对数据进行查看、分析
          </div>
        </div>
        <div class="col" flex-box="0">
          <img
            class="icon"
            src="@/assets/img/ic_200_application.png"
            alt="应用私有化"
          />
          <div class="ttl">应用私有化</div>
          <div class="desc">
            私有云服务，保障集团信息化数据安全，无使用人数、时间、端口等限制
          </div>
        </div>
        <div class="col" flex-box="0">
          <img
            class="icon"
            src="@/assets/img/ic_200_brand.png"
            alt="品牌私有化"
          />
          <div class="ttl">品牌私有化</div>
          <div class="desc">
            专属UI界面、品牌设计服务，知识产权保护，助力企业全面实现数智化转型
          </div>
        </div>
        <div class="col" flex-box="0">
          <img
            class="icon"
            src="@/assets/img/ic_200_service.png"
            alt="落地支持"
          />
          <div class="ttl">落地支持</div>
          <div class="desc">
            提供保姆式专属系统运维服务，7x24小时响应，专属客服跟进项目落地
          </div>
        </div>
      </div>
    </div>
    <div class="box5">
      <div class="title">方案应用场景</div>
      <div class="main_box">
        <div class="row" flex="main:justify">
          <div class="col" flex-box="0">
            <img
              class="pic"
              src="@/assets/img/应用场景_集团会议.png"
              alt="集团会议"
            />
            <div class="ttl">集团会议</div>
          </div>
          <div class="col" flex-box="0">
            <img
              class="pic"
              src="@/assets/img/应用场景_政府考察.png"
              alt="政府考察"
            />
            <div class="ttl">政府考察</div>
          </div>
          <div class="col" flex-box="0">
            <img
              class="pic"
              src="@/assets/img/应用场景_项目汇报.png"
              alt="项目汇报"
            />
            <div class="ttl">项目汇报</div>
          </div>
        </div>
        <div class="row" flex="main:justify">
          <div class="col" flex-box="0">
            <img
              class="pic"
              src="@/assets/img/应用场景_客户考察.png"
              alt="客户考察"
            />
            <div class="ttl">客户考察</div>
          </div>
          <div class="col" flex-box="0">
            <img
              class="pic"
              src="@/assets/img/应用场景_月度会议.png"
              alt="月度会议"
            />
            <div class="ttl">月度会议</div>
          </div>
          <div class="col" flex-box="0">
            <img
              class="pic"
              src="@/assets/img/应用场景_干部培训.png"
              alt="干部培训"
            />
            <div class="ttl">干部培训</div>
          </div>
        </div>
      </div>
    </div>
    <div class="trial_bar">
      <div class="main_box" flex="main:justify cross:center">
        <div class="ttl">为您打造一套数智工程管理解决方案</div>
        <div class="btn" @click="showTrialModal">申请体验</div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Footer,
  },
  methods: {
    ...mapActions(["showTrialModal"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";

.box1 {
  background: url("~@/assets/bg/banner主图.jpg") no-repeat center center;
  background-size: cover;
  height: 32rem;
  overflow: hidden;

  .ttl {
    margin-top: 13.8rem;
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    line-height: 2.8rem;
    letter-spacing: 0.2rem;
  }

  .sub_ttl {
    margin-top: 1.1rem;
    font-size: 1rem;
    color: #fff;
    line-height: 1.5rem;
  }

  .btn {
    margin-top: 3rem;
    display: block;
    width: 6.6rem;
    height: 2rem;
    background-color: #2574ff;
    border-radius: 0.4rem;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    color: #fff;
    line-height: 2rem;

    &:hover {
      background-color: #045af2;
    }
  }
}

.box2 {
  background: url("~@/assets/bg/全盘监控.jpg") no-repeat center center;
  background-size: cover;
  height: 40rem;
  overflow: hidden;

  .ttl {
    margin-top: 7rem;
    font-size: 2rem;
    font-weight: bold;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .sub_ttl {
    margin-top: 1.6rem;
    width: 30rem;
    font-size: 1rem;
    color: #1a1a1a;
    line-height: 1.5rem;
  }
}

.box3 {
  background: url("~@/assets/bg/数据协同.png") no-repeat center center;
  background-size: cover;
  height: 40rem;
  overflow: hidden;

  .ttl {
    margin-top: 7rem;
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    line-height: 3rem;
  }

  .sub_ttl {
    margin-top: 1.6rem;
    width: 30rem;
    font-size: 1rem;
    color: #fff;
    line-height: 1.5rem;
  }
}

.box4 {
  height: 32rem;
  overflow: hidden;
  background-color: #fff;

  .title {
    margin-top: 7rem;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    margin-top: 5.4rem;
  }

  .col {
    width: 15rem;
    text-align: center;

    .icon {
      display: block;
      width: 5rem;
      height: 5rem;
      margin: auto;
    }

    .ttl {
      margin-top: 0.7rem;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      color: #1a1a1a;
      line-height: 1.5rem;
    }

    .desc {
      margin-top: 0.6rem;
      font-size: 0.7rem;
      text-align: center;
      color: #9a9a9a;
      line-height: 1.2rem;
    }
  }
}

.box5 {
  overflow: hidden;
  background-color: #f0f1f6;
  padding: 7rem 0;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .row {
    margin-top: 3rem;
  }

  .col {
    .pic {
      display: block;
      width: 21.25rem;
      height: 14.2rem;
    }

    .ttl {
      margin-top: 1.35rem;
      font-size: 1.2rem;
      text-align: center;
      color: #1a1a1a;
      line-height: 1.8rem;
    }
  }
}

.trial_bar {
  background-color: #f0f1f6;
  padding: 0 0 1.6rem;

  .main_box {
    background: url("~@/assets/bg/申请体验背景.png") no-repeat center center;
    background-size: cover;
    height: 7rem;
  }

  .ttl {
    margin-left: 4rem;
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff;
    line-height: 2.5rem;
    letter-spacing: 0.15rem;
  }

  .btn {
    margin-right: 4rem;
    width: 7rem;
    height: 2.4rem;
    background-color: #fff;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: #0078f0;
    line-height: 2.4rem;
    cursor: pointer;

    &:hover {
      background-color: #dce8ff;
    }
  }
}

.main_box {
  width: $mainWidth;
  margin: auto;
}
</style>
